<template>
  <!-- Main content -->
  <section class="content">
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加频道</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
      v-loading="loading"
      :data="lifeList"
      border
      style="width: 100%;">
    <el-table-column label="id" prop="id" width="80"></el-table-column>
    <el-table-column label="入口名称" prop="name" width="150"></el-table-column>
    <el-table-column label="入口图片">
        <template slot-scope="item">
            <el-image :src="item.row.icon_url" :preview-src-list="[item.row.icon_url]"></el-image>
        </template>
</el-table-column>
<el-table-column label="跳转类型" widt="200">
    <template slot-scope="item">
        {{item.row.redirect_type | redirect}}
    </template>
</el-table-column>
<el-table-column label="安卓跳转链接" prop="android_redirect_url" width="230"></el-table-column>
<el-table-column label="IOS跳转链接" prop="ios_redirect_url" width="230"></el-table-column>
<el-table-column label="跳转标题" prop="title"></el-table-column>
<el-table-column label="频道分类" prop="channel_name"></el-table-column>
<el-table-column label="功能权重" prop="weight"></el-table-column>
<el-table-column label="状态">
    <template slot-scope="item">
        {{item.row.status | status}}
    </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
        <el-button type="primary" size="mini" @click="getLifeChannelDetail(item.row.id)">编辑</el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>
<!-- 修改添加弹窗 -->
<lifeDialog :showExport="show" @clickClose="close" :platform="lifeDetail"></lifeDialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'lifeChannel',
        data() {
            return {
                show: false,
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                lifeList: [],

                lifeDetail: {}


            }
        },
        filters: {
            redirect(val) {
                let name = ''
                if (JSON.stringify(common.redirect_type_list).indexOf(val) != -1) {
                    common.redirect_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '显示'
                        break;
                    case 2:
                        return '隐藏'
                        break;
                    default:
                        return ""
                        break;
                }
            }
        },
        mounted() {
            this.getLifeChannel()
        },
        methods: {
            // 获取列表
            getLifeChannel(page, size) {
                api.getLifeChannel({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                }, res => {
                    this.total = Number(res.data.count)
                    this.lifeList = res.data.list
                    this.loading = false
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getLifeChannel()
            },
            currentChange(val) {
                this.page = val
                this.getLifeChannel()
            },
            //搜索
            seach(page) {
                this.getLifeChannel(page)
            },
            // 获取频道详情
            getLifeChannelDetail(id) {
                api.getLifeChannelDetail({
                    id: id
                }, res => {
                    this.lifeDetail = res.data
                    this.show = true
                })
            },
            // 添加频道
            add() {
                this.lifeDetail = {}
                this.show = true
            },
            // 关闭弹窗
            close() {
                this.show = false
                this.getLifeChannel()
            },
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    /* img {
        height: 40px;
    } */
</style>